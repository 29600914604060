/********************************************************************************************************************************************
*********************************************************** TABLE OF CONTENTS ***************************************************************
********************************************************************************************************************************************/
/*
    1. ROOT VARIABLES
        1.1 Colors - Palette
        1.2 Colors - Backgrounds
        1.3 Colors - Text
        1.4 Shadows

    2. DEFAULT STYLES

    3. GENERIC STYLES
        3.1 Color
        3.2 Typography

    4. HEADER

    5. HOME PAGE

    6. SERVICES PAGE

    7. CONTACT PAGE

    8. FOOTER

    9. MEDIA QUERIES
*/

/********************************************************************************************************************************************
************************************************************ 1. ROOT VARIABLES **************************************************************
********************************************************************************************************************************************/
:root {

    /* ----------------- 1.1 Colors - Palette ----------------- */
    --wood: #BA8C63;
    --light-wood: #C38F5F;
    --dark-wood: #714739;


    /* ---------------- 1.2 Colors - Backgrounds  --------------*/
    --bg-body: #27272a;


    /* ------------------- 1.3 Colors - Text  ------------------*/
    --text-gray: #ababab;


    /* ------------------- 1.4 Shadows  ------------------*/
    --shadow-8: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    --shadow-24: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
}

/********************************************************************************************************************************************
*********************************************************** 2. DEFAULT STYLES ***************************************************************
********************************************************************************************************************************************/
*{
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
}

body, html {
    height: 100%;
}

html {
    scroll-behavior: smooth;
}

body {
    background: var(--bg-body);
    color: #fff;
}

a {
    text-decoration: none;
    color: var(--light-wood);
}

@font-face {
    font-family: 'Wordmean';
    src: local('Wordmean'),
         url('./fonts/Wordmean.ttf') format('truetype');
}


/********************************************************************************************************************************************
************************************************************ 3. GENERIC STYLES **************************************************************
********************************************************************************************************************************************/

/* ------------------- 3.1 Color  ------------------*/
.color-red {
    background-color: red;
}

.color-wood {
    color: var(--text-gray);
}

.shadow-8 {
    box-shadow: var(--shadow-8) !important;
}

.shadow-24 {
    box-shadow: var(--shadow-24) !important;
}

/* ------------------- 3.2 Typography  ------------------*/

/* wordmean */
.wordmean {
    font-family: Wordmean !important;
}

.wordmean-small {
    font-family: Wordmean !important;
    font-size: 1.75rem !important;
}

.wordmean-xs {
    font-family: Wordmean !important;
    font-size: 1.75rem !important;
}

.ls1 {
    letter-spacing: 1px !important;
}
.ls2 {
    letter-spacing: 2px !important;
}
.ls3 {
    letter-spacing: 3px !important;
}
.ls4 {
    letter-spacing: 4px !important;
}
.ls6 {
    letter-spacing: 6px !important;
}
/* -------- */

.text-2 {
    font-size: 2.5rem;
}

.lift-bracket {
    font-size: 1.8rem;
}


/********************************************************************************************************************************************
**************************************************************** 4. HEADER ******************************************************************
********************************************************************************************************************************************/
#header {
    padding: 15px 10%;
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

nav a {
    text-decoration: none;
    color: white;
}

nav ul li {
    display: inline-block;
    list-style: none;
    margin: 10px 20px;
}

nav ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 1rem;
    position: relative;
}

nav ul li a::after {
    content: '';
    width: 0;
    height: 3px;
    background: var(--wood);
    position: absolute;
    left: 0;
    bottom: -6px;
    transition: 0.3s;
}

nav ul li a:hover::after {
    width: 100%;
}

nav .fa-solid {
    display: none;
}



/********************************************************************************************************************************************
************************************************************** 5. HOME PAGE *****************************************************************
********************************************************************************************************************************************/
.hero-img {
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)), url("images/workbench-hero-test.jpg");
    /* background-image: url(images/hero-img2.jpg); */

    /* width: 100vw; */
    height: 500px;

    /* Position and center the image to scale nicely on all screens */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.hero-text {
    text-align: center;
    align-items: center;
    color: white;
}

.testimonials div span {
    text-transform: uppercase;
    font-size: 1rem;
    /* background-color: #d4d4d8;
    color: #52525b;
    padding: 4px;
    border-radius: 5px; */
}

.hover-lift {
    transition: transform 0.3s ease;
}
.hover-lift:hover {
    transform: translate(0, -15px); /* Adjust the values as needed for the desired translation */
}

.hover-text:hover {
    color: #000 !important;
}



/********************************************************************************************************************************************
************************************************************ 6. SERVICES PAGE ***************************************************************
********************************************************************************************************************************************/
#services-page {
    background: #3e3e41;
}

.service-image {
    max-height: 200px;
    max-width: 300px;
}



/********************************************************************************************************************************************
************************************************************ 6. GALLERY PAGE ***************************************************************
********************************************************************************************************************************************/
.gallery-image {
    width: 100%;
    height: auto;
    border-radius: 5px;
}

.gallery-image-no-shadow {
    width: 100%;
    height: auto;
    border-radius: 5px;
}

.slider {
    height: 450px;
    width: 600px;
}

.slide {
    padding: 20px;
}

.carousel-btn {
    padding: 10px 20px;
    
}


/********************************************************************************************************************************************
************************************************************* 7. CONTACT PAGE ***************************************************************
********************************************************************************************************************************************/
#contact-form {
    padding: 40px 20% 50px;
}

form input, form textarea {
    width: 100%;
    border: 0;
    outline: none;
    background: #fff;
    padding: 15px;
    margin: 15px 0;
    color: #000;
    font-size: 1.125rem;
    border-radius: 6px;
}

form input::placeholder {
    color: #afafb6;
}

form textarea::placeholder {
    color: #afafb6;
}

.social-links {
    text-decoration: none;
    cursor: pointer;
    color: #fff;
}

.social-links:hover {
    color: #C38F5F;
}


/********************************************************************************************************************************************
**************************************************************** 8. FOOTER ******************************************************************
********************************************************************************************************************************************/
#footer span {
    color: var(--light-wood);
    text-decoration: none;
}

#footer a {
    color: #fff;
    text-decoration: none;
}

#footer a:hover {
    color: var(--light-wood);
    cursor: pointer;
}



/********************************************************************************************************************************************
*********************************************************** 9. MEDIA QUERIES ***************************************************************
********************************************************************************************************************************************/

/* TABLET VIEW */
@media only screen and (max-width: 821px) {

    
}

/* MOBILE VIEW */
@media only screen and (max-width: 600px) {
    nav .fa-solid {
        display: block;
        font-size: 1.563rem;  /* 25px */
        padding-left: 10px;
    }

    nav ul {
        background: var(--wood);
        position: fixed;
        top: 0;
        right: -200px;
        width: 200px;
        height: 100vh;
        padding-top: 50px;
        z-index: 2;
        transition: right 0.5s;
    }

    nav ul li {
        display: block;
        margin: 25px;
    }

    nav ul .fa-solid {
        position: absolute;
        top: 25px;
        left: 25px;
        cursor: pointer;
    }

    #contact-form {
        padding: 40px 8px 50px;
    }

    .heading-cap {
        font-size: 2.375rem;
    }
}